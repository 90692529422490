@use '@sparbanken-syd/sparbanken-syd-theme/themes' as thm;
@use '@angular/material' as mat;
@include thm.set-typography;

html {
  height: 100%;
  margin: 0;
  width: 100%;
}

body {
  font-family: 'Titillium Web', sans-serif;
  height: 100%;
  margin: 0 auto;
}

/*
 High risk adjustment since Tillium web does not fit properly in inputs
 */
input,
mat-select {
  line-height: 22px !important;
}

mat-label {
  line-height: 22px !important;
}

/* General styling that applies to many pages */

.spb-error {
  background-color: thm.$spb-red;
  color: #fff;
  font-weight: bold;
  margin: 1em auto;
  padding: 0.7em;
  text-align: center;
  width: 90%;
}

/* use this to grow */
.spb-filler {
  display: flex;
  flex-grow: 1;
}

/* Wrap headers, footers and content in this one */
.spb-holder {
  display: flex;
  flex-direction: column;
  height: 100%;
}

/**
 Small tweak on form fields to make them behave
 */
.sbp-standard-full-width {
  width: 66%;
}

@media screen and (max-width: thm.$spb-small) {
  .spb-standard-full-width {
    padding: initial;
    width: 100%;
  }
}

/**
  Checkbox labels do not line break and have to be here ... 2019-08-08 /Daniel
 */
.mat-checkbox-layout {
  white-space: normal !important;
}

.mat-radio-label {
  white-space: normal !important;
}

.mat-checkbox-inner-container {
  margin: 4px 8px auto 0 !important;
}

h1,
h2,
h3 {
  margin-bottom: 7px !important;
  margin-top: 15px !important;
}

h4 {
  margin-bottom: 7px !important;
  margin-top: 20px !important;
}

a {
  color: #232323;
}

/*
Header in tables
*/
th {
  color: rgb(0 0 0 / 54%) !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  white-space: nowrap !important;
}

/**
Hide scrollbar in table
 */
.mat-mdc-tab-body-content {
  overflow: hidden !important;
}

// All input and select should be black and not yellow
span {
  color: inherit !important
}
